// Translated
<template>
  <transition :name="transition">
    <!-- containerClass-->
    <div
      v-if="isVisible"
      ref="backdrop"
      class="vh-100 vw-100 top-0 left-0 absolute"
      :style="containerStyle"
      :class="containerClass"
    >
      <!-- modalClass-->
      <div
        class="modal !overflow-y-auto overscroll-contain"
        role="dialog"
        aria-modal="true"
        :class="modalClasses"
        :data-keyboard="!noCloseOnEsc"
      >
        <!-- dialogClass-->
        <div
          :class="dialogClasses"
          :style="{ aspectRatio }"
        >
          <span tabindex="0" />
          <!-- contentClass-->
          <div
            ref="content"
            class="modal-content mt-4"
            tabindex="-1"
            :style="{ aspectRatio }"
            :class="contentClasses"
          >
            <header
              v-if="!hideHeader"
              class="modal-header"
            >
              <slot name="modal-header">
                <span v-if="title">
                  {{ title }}
                </span>
                <slot name="modal-header-content" />
              </slot>
            </header>
            <!-- bodyClass-->
            <div
              class="modal-body"
              :class="bodyClass"
            >
              <slot />
            </div>
            <div
              v-if="!hideFooter"
              class="modal-footer"
              :class="{ 'modal-footer--sticky': stickyFooter }"
            >
              <slot name="modal-footer" />
            </div>
          </div>
          <span tabindex="0" />
        </div>
      </div>
      <div
        class="modal-backdrop fade"
        :class="{ show: isVisible }"
      />
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'pinia'

export default defineNuxtComponent({
  name: 'Modal',

  props: {
    transition: {
      type: String,
      default: 'modal-fade',
    },

    modelValue: {
      type: Boolean,
      default: false,
    },

    visible: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: null,
    },

    centered: {
      type: Boolean,
      default: false,
    },

    hideFooter: {
      type: Boolean,
      default: false,
    },

    hideHeader: {
      type: Boolean,
      default: false,
    },

    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },

    noCloseOnEsc: {
      type: Boolean,
      default: false,
    },

    modalClass: {
      type: String,
      default: null,
    },

    dialogClass: {
      type: String,
      default: null,
    },

    containerClass: {
      type: String,
      default: null,
    },

    bodyClass: {
      type: String,
      default: null,
    },

    contentClass: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: null,
    },

    stickyFooter: {
      type: Boolean,
      default: false,
    },

    aspectRatio: {
      type: String,
      default: null,
    },
  },

  emits: ['hide', 'hidden', 'update:modelValue'],

  data () {
    return {
      containerStyle: {
        position: 'absolute',
        'z-index': 1040,
      },
    }
  },

  computed: {
    dialogClasses () {
      const classes = []
      if (this.size) {
        classes.push(`modal-${this.size}`)
      }

      if (this.dialogClass) {
        classes.push(this.dialogClass)
      }

      if (this.centered) {
        if (this.aspectRatio) {
          classes.push('!flex items-center')
        } else {
          classes.push('modal-dialog-centered')
        }
      }

      if (this.aspectRatio) {
        classes.push('max-h-full')
      } else {
        classes.push('modal-dialog')
      }

      return classes.join(' ')
    },

    contentClasses () {
      const classes = [this.contentClass]
      return classes.join(' ')
    },

    modalClasses () {
      const classes = [this.modalClass]

      if (this.modelValue || this.visible) {
        if (this.centered && this.aspectRatio) {
          classes.push('!flex justify-center')
        } else {
          classes.push('!block')
        }
      }

      return classes.join(' ')
    },

    isVisible () {
      return this.modelValue || this.visible
    },
  },

  watch: {
    modelValue (v) {
      this.toggleModal(v)
    },

    visible (v) {
      this.toggleModal(v)
    },
  },

  mounted () {
    if (this.visible) {
      this.show()
    }
  },

  beforeUnmount () {
    this.hide()
  },

  methods: {
    ...mapActions(useRootStore, {
      'setModalActive': 'SET_MODAL_ACTIVE',
    }),

    toggleModal (v) {
      this.$nextTick(() => {
        if (v) {
          this.show()
        } else {
          this.hide()
        }
      })
    },

    closeBackdrop (event) {
      if (event.which !== 1 || this.$refs.content?.contains(event.target) || !document.body.contains(event.target)) {
        return
      }

      this.hide('backdrop')
    },

    show () {
      this.containerStyle['z-index'] = Math.max(
        ...[...document.querySelectorAll('.modal')].map(el => Number(el.parentElement.style.zIndex))
      ) + 1

      document.body.classList.add('overflow-hidden')
      this.setModalActive(true)

      // Deprecated?
      if (this.$refs.backdrop) {
        this.$refs.backdrop.addEventListener('click', this.closeBackdrop)
      }
    },

    hide (source) {
      if (source === 'backdrop' && this.noCloseOnBackdrop) {
        return
      }

      this.containerStyle['z-index'] = 1040

      if (this.$refs.backdrop) {
        this.$refs.backdrop.removeEventListener('click', this.closeBackdrop)
      }

      document.body.classList.remove('overflow-hidden')

      this.$emit('update:modelValue', false)
      this.$emit('hide', true)
      this.$emit('hidden')

      this.setModalActive(false)
    },
  },
})
</script>
